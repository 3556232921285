/* Adjust useFlexLayout hook styles */
.react-table-useFlexLayout td,
.react-table-useFlexLayout th {
  display: flex;
  align-self: stretch;
  align-items: center;
}

/* Current tables only */
tbody > tr {
  min-height: 60px;
}

/* Multi select all checkbox needs higher z-index than default sticky index to display dialog over the sticky row checkboxes */
th[data-sticky-last-left-td='true'] {
  z-index: 30 !important;
}

tr:hover .expand-icon-container {
  transition: transform 0.15s ease-in-out;
  transform: scale(1.4);
}

tbody > tr.sub-row {
  min-height: unset;
}

tr.isEven {
  background-color: var(--palette-neutral-lightest, #f2f2f2);
}

tr.sub-row {
  color: #656565;
}
