/* Adjust useFlexLayout hook styles */
.react-table-useFlexLayout td,
.react-table-useFlexLayout th {
  display: flex;
  align-self: stretch;
  align-items: center;
}

/* Current tables only */
tbody > tr {
  min-height: 60px;
}

tr:hover .expand-icon-container {
  transition: transform 0.15s ease-in-out;
  transform: scale(1.4);
}

tbody > tr.sub-row {
  min-height: unset;
}

tr.isEven {
  background-color: var(--palette-neutral-lightest, #f2f2f2);
}

tr.sub-row {
  color: #656565;
}

.ev-table-cell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 60px;
  background-color: #f2f2f2;
}

.ev-table-body {
  text-align: left;
  font-size: var(--typography-size-md);
  padding: var(--structure-spacing-sm) var(--structure-spacing-md);
  overflow: hidden;
  text-overflow: ellipsis;
}

.ev-page-div {
  align-self: self-end;
  margin-bottom: 30px;
}

.ev-page-note {
  font-size: var(--typography-size-md);
  margin-top: 20px;
}
